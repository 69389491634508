import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Action } from '@radix-ui/react-alert-dialog';
import type { AlertDialogActionProps as ActionProps } from '@radix-ui/react-alert-dialog';

export interface AlertDialogActionProps
  extends StyleProps<Omit<ActionProps, 'asChild'>> {}

const AlertDialogAction = React.forwardRef<
  ElementRef<typeof Action>,
  AlertDialogActionProps
>((props, ref) => {
  return <Action ref={ref} asChild {...props} />;
});

AlertDialogAction.displayName = '@backyard-ui/core/AlertDialogAction';

export default AlertDialogAction;
