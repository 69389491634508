import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Description } from '@radix-ui/react-dialog';
import type { DialogDescriptionProps } from '@radix-ui/react-dialog';

import styles from './Drawer.styles';

export interface DrawerDescriptionProps
  extends StyleProps<Omit<DialogDescriptionProps, 'asChild'>> {}

const DrawerDescription = React.forwardRef<
  ElementRef<typeof Description>,
  DrawerDescriptionProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, children, ...rest } = props;

  return (
    <Description
      className={styles().description({ className: UNSAFE_className })}
      style={UNSAFE_style}
      ref={ref}
      asChild
      {...rest}
    >
      <div>{children}</div>
    </Description>
  );
});

DrawerDescription.displayName = '@backyard-ui/core/DrawerDescription';

export default DrawerDescription;
