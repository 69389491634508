import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    inline-flex
    h-5

    cursor-default
    select-none

    items-center
    justify-center
    gap-x-1

    rounded-xl

    px-2

    text-center
    text-sm
    leading-none
    font-semibold
    uppercase
  `,
  slots: {
    inner: `
      line-clamp-1
      py-1
    `,
    icon: `
      flex
      shrink-0

      [&_svg]:h-3
      [&_svg]:w-3
    `,
  },
  variants: {
    appearance: {
      critical: '',
      info: '',
      neutral: '',
      primary: '',
      secondary: '',
      success: '',
      tertiary: '',
      warning: '',
    },
    color: {
      orange: {
        base: `
          bg-orange-200
          text-orange-800
      `,
      },
    },
    variant: {
      solid: '',
      subtle: '',
    },
    isFullWidth: {
      true: 'w-full',
    },
  },
  compoundVariants: [
    //solid
    {
      appearance: 'critical',
      variant: 'solid',
      className: {
        base: `
          bg-critical-700
          text-white
        `,
      },
    },
    {
      appearance: 'info',
      variant: 'solid',
      className: {
        base: `
          bg-info-800
          text-white
        `,
      },
    },
    {
      appearance: 'neutral',
      variant: 'solid',
      className: {
        base: `
          bg-neutral-800
          text-white
        `,
      },
    },
    {
      appearance: 'primary',
      variant: 'solid',
      className: {
        base: `
          bg-primary-700
          text-white
        `,
      },
    },
    {
      appearance: 'secondary',
      variant: 'solid',
      className: {
        base: `
          bg-secondary-600
          text-white
        `,
      },
    },
    {
      appearance: 'success',
      variant: 'solid',
      className: {
        base: `
          bg-success-700
          text-white
        `,
      },
    },
    {
      appearance: 'tertiary',
      variant: 'solid',
      className: {
        base: `
          bg-tertiary-700
          text-white
        `,
      },
    },
    {
      appearance: 'warning',
      variant: 'solid',
      className: {
        base: `
          bg-warning-500
          text-neutral-900
        `,
      },
    },
    //subtle
    {
      appearance: 'critical',
      variant: 'subtle',
      className: {
        base: `
          bg-critical-200
          text-critical-800
        `,
      },
    },
    {
      appearance: 'info',
      variant: 'subtle',
      className: {
        base: `
          bg-info-200
          text-info-800
        `,
      },
    },
    {
      appearance: 'neutral',
      variant: 'subtle',
      className: {
        base: `
          bg-neutral-200
          text-neutral-800
        `,
      },
    },
    {
      appearance: 'primary',
      variant: 'subtle',
      className: {
        base: `
          bg-primary-200
          text-primary-800
        `,
      },
    },
    {
      appearance: 'secondary',
      variant: 'subtle',
      className: {
        base: `
          bg-secondary-200
          text-secondary-800
        `,
      },
    },
    {
      appearance: 'success',
      variant: 'subtle',
      className: {
        base: `
          bg-success-200
          text-success-800
        `,
      },
    },
    {
      appearance: 'tertiary',
      variant: 'subtle',
      className: {
        base: `
          bg-tertiary-100
          text-tertiary-700
        `,
      },
    },
    {
      appearance: 'warning',
      variant: 'subtle',
      className: {
        base: `
          bg-warning-200
          text-neutral-900
        `,
      },
    },
  ],
});

export default styles;
