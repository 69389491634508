import React from 'react';
import type { ElementRef } from 'react';

import type { UnstyledProps } from '@backyard-ui/styles';

import { Cancel } from '@radix-ui/react-alert-dialog';
import type { AlertDialogCancelProps as CancelProps } from '@radix-ui/react-alert-dialog';

export interface AlertDialogCancelProps extends UnstyledProps<CancelProps> {}

const AlertDialogCancel = React.forwardRef<
  ElementRef<typeof Cancel>,
  AlertDialogCancelProps
>((props, ref) => {
  return <Cancel ref={ref} {...props} />;
});

AlertDialogCancel.displayName = '@backyard-ui/core/AlertDialogCancel';

export default AlertDialogCancel;
