import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Close } from '@radix-ui/react-dialog';
import type { DialogCloseProps } from '@radix-ui/react-dialog';

import { CloseButton } from '../CloseButton';

import styles from './Drawer.styles';

export interface DrawerCloseButtonProps extends StyleProps<DialogCloseProps> {}

const DrawerCloseButton = React.forwardRef<
  ElementRef<typeof Close>,
  DrawerCloseButtonProps
>((props, ref) => {
  const { UNSAFE_className, ...rest } = props;

  return (
    <Close ref={ref} asChild {...rest}>
      <CloseButton
        UNSAFE_className={styles().close({ className: UNSAFE_className })}
        size="xs"
      />
    </Close>
  );
});

DrawerCloseButton.displayName = '@backyard-ui/core/DrawerCloseButton';

export default DrawerCloseButton;
