import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Title } from '@radix-ui/react-alert-dialog';
import type { AlertDialogTitleProps as TitleProps } from '@radix-ui/react-alert-dialog';

import styles from '../Dialog/Dialog.styles';

export interface AlertDialogTitleProps
  extends StyleProps<Omit<TitleProps, 'asChild'>> {}

const AlertDialogTitle = React.forwardRef<
  ElementRef<typeof Title>,
  AlertDialogTitleProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <Title
      className={styles().title({ className: UNSAFE_className })}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

AlertDialogTitle.displayName = '@backyard-ui/core/AlertDialogTitle';

export default AlertDialogTitle;
