import React from 'react';
import type { ElementRef } from 'react';

import type { UnstyledProps } from '@backyard-ui/styles';

import { Trigger } from '@radix-ui/react-dialog';
import type { DialogTriggerProps } from '@radix-ui/react-dialog';

export interface DrawerTriggerProps extends UnstyledProps<DialogTriggerProps> {}

const DrawerTrigger = React.forwardRef<
  ElementRef<typeof Trigger>,
  DrawerTriggerProps
>((props, ref) => {
  return <Trigger ref={ref} {...props} />;
});

DrawerTrigger.displayName = '@backyard-ui/core/DrawerTrigger';

export default DrawerTrigger;
