import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Description } from '@radix-ui/react-alert-dialog';
import type { AlertDialogDescriptionProps as DescriptionProp } from '@radix-ui/react-alert-dialog';

import styles from '../Dialog/Dialog.styles';

export interface AlertDialogDescriptionProps
  extends StyleProps<Omit<DescriptionProp, 'asChild'>> {}

const AlertDialogDescription = React.forwardRef<
  ElementRef<typeof Description>,
  AlertDialogDescriptionProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, children, ...rest } = props;

  return (
    <Description
      className={styles().description({ className: UNSAFE_className })}
      style={UNSAFE_style}
      ref={ref}
      asChild
      {...rest}
    >
      <div>{children}</div>
    </Description>
  );
});

AlertDialogDescription.displayName = '@backyard-ui/core/AlertDialogDescription';

export default AlertDialogDescription;
