import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import styles from './Badge.styles';

export interface BadgeProps extends StyleProps<HTMLAttributes<HTMLDivElement>> {
  /**
   * The badge appearance
   *
   * @default primary
   */
  appearance?:
    | 'critical'
    | 'info'
    | 'neutral'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'tertiary'
    | 'warning';

  /**
   * The badge color
   */
  color?: 'orange';

  /**
   * The variant is used
   * to change the visual communication
   *
   * @default solid
   */
  variant?: 'solid' | 'subtle';

  /**
   * Icon rendered on the left side of label
   */
  iconLeft?: React.ReactNode;

  /**
   * Icon rendered on the right side of label
   */
  iconRight?: React.ReactNode;

  /**
   * Set `width` to 100%
   */
  isFullWidth?: boolean;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The children elements
   */
  children: ReactNode;
}

const Badge = React.forwardRef<ElementRef<'div'>, BadgeProps>((props, ref) => {
  const {
    appearance = 'primary',
    color,
    variant = 'solid',
    isFullWidth = false,
    children,
    iconLeft,
    iconRight,
    UNSAFE_className,
    UNSAFE_style,
    ...rest
  } = props;

  return (
    <div
      className={styles({
        appearance: color ? undefined : appearance,
        color,
        variant,
        isFullWidth,
      }).base({
        className: UNSAFE_className,
      })}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    >
      {Boolean(iconLeft) && (
        <span className={styles().icon()} data-testid="Badge-icon-left">
          {iconLeft}
        </span>
      )}
      <span className={styles().inner()}>{children}</span>
      {Boolean(iconRight) && (
        <span className={styles().icon()} data-testid="Badge-icon-right">
          {iconRight}
        </span>
      )}
    </div>
  );
});

Badge.displayName = '@backyard-ui/core/Badge';

export default Badge;
