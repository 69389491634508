import { createContext } from '@backyard-ui/utils';
import type { AlertDialogProps } from './AlertDialog';

export interface AlertDialogContext
  extends Pick<
    AlertDialogProps,
    'size' | 'defaultOpen' | 'isCentered' | 'isOpen' | 'onOpenChange'
  > {}

const { Provider: AlertDialogProvider, useContext: useAlertDialogContext } =
  createContext<AlertDialogContext>({
    name: '@backyard-ui/core/AlertDialogContext',
  });

export { AlertDialogProvider, useAlertDialogContext };
