import React from 'react';
import type { ElementRef, Ref } from 'react';

import { Root } from '@radix-ui/react-alert-dialog';
import type { AlertDialogProps as DialogProps } from '@radix-ui/react-alert-dialog';

import { AlertDialogProvider } from './AlertDialog.context';

export interface AlertDialogProps extends Omit<DialogProps, 'open'> {
  /**
   * The `max-width` css property
   *
   * @default xl
   */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'full';

  /**
   * Vertically center content
   */
  isCentered?: boolean;

  /**
   * Controls open/close state
   */
  isOpen?: boolean;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;
}

const AlertDialog = React.forwardRef<ElementRef<'div'>, AlertDialogProps>(
  (props, ref) => {
    const { size = 'xl', isCentered = false, isOpen, ...rest } = props;

    return (
      <AlertDialogProvider value={{ size, isCentered }}>
        <Root open={isOpen} ref={ref} {...rest} />
      </AlertDialogProvider>
    );
  }
);

AlertDialog.displayName = '@backyard-ui/core/AlertDialog';

export default AlertDialog;
