import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import styles from './Drawer.styles';

export interface DrawerFooterProps
  extends StyleProps<HTMLAttributes<HTMLDivElement>> {
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLElement>;

  /**
   * The children elements
   */
  children: ReactNode;
}

const DrawerFooter = React.forwardRef<ElementRef<'footer'>, DrawerFooterProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style, ...rest } = props;

    return (
      <footer
        className={styles().footer({ className: UNSAFE_className })}
        style={UNSAFE_style}
        ref={ref}
        {...rest}
      />
    );
  }
);

DrawerFooter.displayName = '@backyard-ui/core/DrawerFooter';

export default DrawerFooter;
