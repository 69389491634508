import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    fixed
    left-0
    top-0

    z-50

    flex

    h-screen
    w-screen

    overflow-hidden
  `,
  slots: {
    close: `
      absolute
      right-6
      top-4;
    `,
    content: `
      relative

      flex
      w-full
      flex-col

      bg-white

      px-6
      py-4

      outline-none

      will-change-transform
      animate-in
      ease-in-out

      data-[state=open]:animate-in
      data-[state=closed]:animate-out
      data-[state=closed]:duration-200
      data-[state=open]:duration-500
      data-[state]:motion-reduce:animate-none
    `,
    title: `
      block
      pb-4

      font-semibold
      heading-xl
      md:heading-2xl
    `,
    description: `
      block
      flex-1
      overflow-auto

      py-2
      text-md
      md:text-lg
    `,
    footer: `
      flex
      justify-end

      space-x-2
      pt-4
    `,
    portal: 'z-50',
  },
  variants: {
    placement: {
      bottom: {
        base: 'items-end',
        content: 'data-[state=open]:slide-in-from-bottom',
      },
      left: {
        base: 'justify-start',
        content: 'data-[state=open]:slide-in-from-left',
      },
      top: {
        base: 'items-start',
        content: 'data-[state=open]:slide-in-from-top',
      },
      right: {
        base: 'justify-end',
        content: 'data-[state=open]:slide-in-from-right',
      },
    },
    size: {
      xs: {
        content: 'max-w-xs',
      },
      sm: {
        content: 'max-w-sm',
      },
      md: {
        content: 'max-w-md',
      },
      lg: {
        content: 'max-w-lg',
      },
      xl: {
        content: 'max-w-xl',
      },
      '2xl': {
        content: 'max-w-2x',
      },
      '3xl': {
        content: 'max-w-4x',
      },
      full: {
        content: 'max-w-ful',
      },
    },
  },
});

export default styles;
