import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import styles from '../Dialog/Dialog.styles';

export interface AlertDialogFooterProps
  extends StyleProps<HTMLAttributes<HTMLDivElement>> {
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLElement>;

  /**
   * The children elements
   */
  children: ReactNode;
}

const AlertDialogFooter = React.forwardRef<
  ElementRef<'footer'>,
  AlertDialogFooterProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <footer
      className={styles().footer({ className: UNSAFE_className })}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

AlertDialogFooter.displayName = '@backyard-ui/core/AlertDialogFooter';

export default AlertDialogFooter;
