import { createContext } from '@backyard-ui/utils';
import type { DrawerProps } from './Drawer';

export interface DrawerContext
  extends Pick<
    DrawerProps,
    | 'placement'
    | 'size'
    | 'defaultOpen'
    | 'isOpen'
    | 'closeOnOverlayClick'
    | 'onOpenChange'
  > {}

const { Provider: DrawerProvider, useContext: useDrawerContext } =
  createContext<DrawerContext>({
    name: '@backyard-ui/core/DrawerContext',
  });

export { DrawerProvider, useDrawerContext };
