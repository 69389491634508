import React from 'react';
import type { ElementRef } from 'react';

import type { UnstyledProps } from '@backyard-ui/styles';

import { Close } from '@radix-ui/react-dialog';
import type { DialogCloseProps } from '@radix-ui/react-dialog';

export interface DrawerCloseProps extends UnstyledProps<DialogCloseProps> {}

const DrawerClose = React.forwardRef<
  ElementRef<typeof Close>,
  DrawerCloseProps
>((props, ref) => {
  return <Close ref={ref} {...props} />;
});

DrawerClose.displayName = '@backyard-ui/core/DrawerClose';

export default DrawerClose;
