export { default as Root } from './AlertDialog';
export type { AlertDialogProps } from './AlertDialog';

export { default as Action } from './Action';
export type { AlertDialogActionProps } from './Action';

export { default as Trigger } from './Trigger';
export type { AlertDialogTriggerProps } from './Trigger';

export { default as Cancel } from './Cancel';
export type { AlertDialogCancelProps } from './Cancel';

export { default as Content } from './Content';
export type { AlertDialogContentProps } from './Content';

export { default as Title } from './Title';
export type { AlertDialogTitleProps } from './Title';

export { default as Description } from './Description';
export type { AlertDialogDescriptionProps } from './Description';

export { default as Footer } from './Footer';
export type { AlertDialogFooterProps } from './Footer';
