import React from 'react';
import type { ElementRef, Ref } from 'react';

import { Root } from '@radix-ui/react-dialog';
import type { DialogProps } from '@radix-ui/react-dialog';

import { DrawerProvider } from './Drawer.context';

export interface DrawerProps extends Omit<DialogProps, 'open'> {
  /**
   * The drawer position
   *
   * @default right
   */
  placement?: 'bottom' | 'left' | 'top' | 'right';

  /**
   * The `max-width` css property
   *
   * @default md
   */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'full';

  /**
   * If `false` the Dialog will be close when clicked on overlay
   *
   * @default false
   */
  closeOnOverlayClick?: boolean;

  /**
   *  If `true` the Dialog will be initially opened
   *
   * @default false
   */
  isOpen?: boolean;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;
}

const Drawer = React.forwardRef<ElementRef<typeof Root>, DrawerProps>(
  (props, ref) => {
    const {
      placement = 'right',
      size = 'md',
      closeOnOverlayClick = true,
      isOpen,
      ...rest
    } = props;

    return (
      <DrawerProvider value={{ placement, size, closeOnOverlayClick }}>
        <Root ref={ref} open={isOpen} {...rest} />
      </DrawerProvider>
    );
  }
);

Drawer.displayName = '@backyard-ui/core/Drawer';

export default Drawer;
