import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Title } from '@radix-ui/react-dialog';
import type { DialogTitleProps } from '@radix-ui/react-dialog';

import styles from './Drawer.styles';

export interface DrawerTitleProps
  extends StyleProps<Omit<DialogTitleProps, 'asChild'>> {}

const DrawerTitle = React.forwardRef<
  ElementRef<typeof Title>,
  DrawerTitleProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <Title
      className={styles().title({ className: UNSAFE_className })}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

DrawerTitle.displayName = '@backyard-ui/core/DrawerTitle';

export default DrawerTitle;
