import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Content, Portal, Overlay } from '@radix-ui/react-dialog';
import type { DialogContentProps } from '@radix-ui/react-dialog';

import { Overlay as OverlayBase } from '../Overlay';
import { useDrawerContext } from './Drawer.context';

import styles from './Drawer.styles';

export interface DrawerContentProps
  extends StyleProps<Omit<DialogContentProps, 'asChild'>> {}

const DrawerContent = React.forwardRef<
  ElementRef<typeof Content>,
  DrawerContentProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, onPointerDownOutside, ...rest } =
    props;
  const { size, placement, closeOnOverlayClick } = useDrawerContext();

  const onOutsideClick = React.useCallback(
    (
      event: CustomEvent<{
        originalEvent: PointerEvent;
      }>
    ) => {
      if (!closeOnOverlayClick) {
        event.preventDefault();

        return onPointerDownOutside?.(event);
      }

      return onPointerDownOutside?.(event);
    },
    [closeOnOverlayClick, onPointerDownOutside]
  );

  const placementSize =
    placement === 'top' || placement === 'bottom' ? 'full' : size;

  return (
    <Portal>
      <div className={styles().portal()}>
        <Overlay asChild>
          <OverlayBase />
        </Overlay>
        <div
          className={styles({ placement }).base({
            className: UNSAFE_className,
          })}
          style={UNSAFE_style}
        >
          <Content
            className={styles({ placement, size: placementSize }).content()}
            onPointerDownOutside={onOutsideClick}
            ref={ref}
            {...rest}
          />
        </div>
      </div>
    </Portal>
  );
});

DrawerContent.displayName = '@backyard-ui/core/DrawerContent';

export default DrawerContent;
