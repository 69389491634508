import React from 'react';
import type { ElementRef, Ref } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Content, Portal, Overlay } from '@radix-ui/react-alert-dialog';
import type { AlertDialogContentProps as ContentProps } from '@radix-ui/react-alert-dialog';

import { Overlay as OverlayBase } from '../Overlay';
import { useAlertDialogContext } from './AlertDialog.context';

import styles from '../Dialog/Dialog.styles';

export interface AlertDialogContentProps
  extends StyleProps<Omit<ContentProps, 'asChild'>> {
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;
}

const AlertDialogContent = React.forwardRef<
  ElementRef<typeof Content>,
  AlertDialogContentProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;
  const { size, isCentered } = useAlertDialogContext();

  return (
    <Portal>
      <div className={styles().portal()}>
        <Overlay asChild>
          <OverlayBase />
        </Overlay>
        <div
          className={styles({ isCentered }).base({
            className: UNSAFE_className,
          })}
          style={UNSAFE_style}
        >
          <Content className={styles({ size }).content()} ref={ref} {...rest} />
        </div>
      </div>
    </Portal>
  );
});

AlertDialogContent.displayName = '@backyard-ui/core/AlertDialogContent';

export default AlertDialogContent;
