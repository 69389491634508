import React from 'react';
import type { ElementRef } from 'react';

import type { UnstyledProps } from '@backyard-ui/styles';

import { Trigger } from '@radix-ui/react-alert-dialog';
import type { AlertDialogTriggerProps as TriggerProps } from '@radix-ui/react-alert-dialog';

export interface AlertDialogTriggerProps extends UnstyledProps<TriggerProps> {}

const AlertDialogTrigger = React.forwardRef<
  ElementRef<typeof Trigger>,
  AlertDialogTriggerProps
>((props, ref) => {
  return <Trigger ref={ref} {...props} />;
});

AlertDialogTrigger.displayName = '@backyard-ui/core/AlertDialogTrigger';

export default AlertDialogTrigger;
